<template>
  <div id="checkoutItem">
      <div>
          <el-badge :value="item.amount" type="primary">
            <el-image :src="item.product.image" fit="fit" style="width:64px;height:64px;border-radius:5px;border:1px solid #E4E7ED;"></el-image>
          </el-badge>
      </div>
      <div style="margin-left:20px;margin-top:15px;-webkit-box-flex:1;">
          <div style="font-size:16px;">{{item.product.name}}</div>
          <div style="margin-top:10px;color:grey;font-size:14px;">{{item.sku.name}}</div>
      </div>
      <div style="line-height:64px;text-align:right;font-weight:bold;font-size:14px;">
          <span v-if="isMember">${{(item.sub_member_total/100).toFixed(2)}}</span>
          <span v-else>${{(item.sub_total/100).toFixed(2)}}</span>
      </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'checkoutItem',
    props: ['item'],
    computed: {
        ...mapGetters('client', ['isMember']),
    }
}
</script>

<style>
#checkoutItem{
    display: -webkit-box;
    margin: 0px 0px 10px 0px;
}
</style>