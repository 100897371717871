<template>
  <div id="checkoutDeliverItem">
      <div style="font-size:14px;padding:0px 0px 5px 0px;margin-bottom:24px;display:-webkit-box;height:36px;line-height:36px;border-bottom: 2px dotted #DCDFE6;"><strong>Deliver on</strong> <span style="font-size:16px;margin-left:10px;margin-right:10px;">{{dateFormat}}</span> 
        <el-button v-if="showChange" size="mini" type="danger" @click="changeDeliverTime" style="font-size:12px;margin-top:0px;">Change Date</el-button>
      </div>
      <checkout-item v-for="item in items.goods" :key="item.key" :item="item"></checkout-item>
      <el-divider></el-divider>
      <el-dialog :visible.sync="showDeliverTimePickup" title="Change Deliver Date" width="350px">
          <div>
              <el-date-picker type="date" :picker-options="pickerOptions" v-model="selectDate" :clearable="false"></el-date-picker>
          </div>
          <div slot="footer" class="dialog-footer">
            <div style="-webkit-box-flex:1;"></div>
            <div style="margin-right:30px;"><el-button @click="showDeliverTimePickup = false">Cancel</el-button></div>
            <div><el-button type="primary" @click="changePickerDate">Submit</el-button></div>
          </div>
      </el-dialog>
  </div>
</template>

<script>
import util from '../../js/util'
import CheckoutItem from './CheckoutItem.vue'

export default {
    name: 'checkoutDeliverItem',
    components: {
        CheckoutItem
    },
    props:['items', 'showChange'],
    data(){
        return {
            showDeliverTimePickup: false,
            selectDate: new Date(),
            pickerOptions: {
                disabledDate: (time)=>{
                    return time.getTime() < this.items.expectDeliverDate - 24 * 3600 * 1000
                }
            }
        }
    },
    computed: {
        dateFormat(){
            return util.dateFormat('YYYY-mm-dd', this.items.deliverDate)
        }
    },
    methods: {
        changeDeliverTime(){
            this.selectDate = this.items.deliverDate
            this.showDeliverTimePickup = true
        },
        changePickerDate(){
            this.showDeliverTimePickup = false
            this.$emit('onDateChange', this.selectDate, this.items.key)
        }
    }
}
</script>

<style>

</style>