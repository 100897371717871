<template>
  <div></div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import util from '../../js/util'

export default {
    name: 'checkout',
    data(){
        return {
            contactName: '',
            contactNumber: '',
            selectLocations: [],
            goodListByDeliverDate: {},
            expectedLocation: 0,
            shippingMethod: 'free',
            showPayment: 0,
            paymentMethod: 'sgqr',
            payRequest: {
                qrCode: '',
                orderId: 0
            },
            paynowLoading: false,
            timer: null
        }
    },
    watch: {
        expectedLocation(val, oldVal){
            console.log(val, oldVal)
            if(val !== 0){
                this.setShipping({
                    location: this.selectLocations[val]
                })
            }
        }
    },
    computed: {
        ...mapGetters('client', ['isMember']),
        ...mapState('client', ['client']),
        ...mapState('util', ['locations']),
        ...mapState('cart', ['goodList']),
        ...mapGetters('cart', ['cartTotal','cartMemberTotal']),
        showAnounce(){
            var currentTime = new Date()
            if(currentTime.getHours() < 17){
                return false
            } else {
                return true
            }
        },
        expectedLocationName(){
            if(this.selectLocations.length == 0) return ''
            return this.selectLocations[this.expectedLocation].name
        },
        expectedLocationAddress(){
            if(this.selectLocations.length == 0) return ''
            return this.selectLocations[this.expectedLocation].address
        }
    },
    mounted(){
        if(this.client === null) {
            this.contactName = ''
            this.contactNumber = ''
        } else {
            this.contactName = this.client.name
            this.contactNumber = this.client.contact_no
        }
        this.getLocations().then(()=>{
            this.selectLocations.push({
                ID: 0,
                name: 'Not Select'
            })
            this.selectLocations.push(...this.locations)
        })
        this.initGoodListByDeliverDate()
    },
    methods: {
        ...mapActions('util', [
            'getLocations'
        ]),
        ...mapActions('cart', ['checkout', 'setShipping', 'clearGoods']),
        ...mapActions('order', ['getOrderStatus']),
        homePageClick(){
            window.location.href = '/'
        },
        gotoPaymentClick(){
            console.log(this.contactName, this.expectedLocation, this.shippingMethod)
            if(this.contactName.length === 0){
                this.$message({
                    message: 'contact name cannot be empty',
                    type: 'error'
                })
                return
            }
            if(this.expectedLocation === 0){
                this.$message({
                    message: 'shipping address is needed',
                    type: 'error'
                })
                return
            }
            this.showPayment = 1
        },
        initGoodListByDeliverDate() {
            var goodDateDict = {}
            this.goodList.forEach(good=>{
                console.log(good)
                var date = good.product.min_collection_date
                if(date in goodDateDict) {
                    goodDateDict[date].push(good)
                } else {
                    goodDateDict[date] = [good]
                }
            })
            var list = []
            for(var key in goodDateDict){
                var day = util.getExpectedDeliverDay(key)
                list.push({
                    key: key,
                    deliverDate: day,
                    expectDeliverDate: day,
                    goods: goodDateDict[key]
                })
            }
            console.log(list)
            this.goodListByDeliverDate = list
        },
        onDateChange(date, key){
            for(var i = 0; i < this.goodListByDeliverDate.length; i++){
                if(this.goodListByDeliverDate[i].key === key){
                    this.goodListByDeliverDate[i].deliverDate = date
                    break
                }
            }
        },
        paynowClick(){
            this.paynowLoading = true
            var goodList = []
            this.goodListByDeliverDate.forEach(deliver=>{
                var date = util.dateFormat('YYYY-mm-dd', deliver.deliverDate)
                deliver.goods.forEach(good=>{
                    good.deliver_date = date
                    goodList.push(good)
                })
            })
            console.log(goodList)
            this.checkout({
                goodList: goodList,
                contact: {
                    name: this.contactName,
                    mobile: this.contactNumber
                }
            }).then(resp=>{
                console.log(resp)
                this.payRequest.qrCode = resp.qr_code
                this.payRequest.orderId = resp.order_id
                this.showPayment = 2
                this.paynowLoading = false
                this.timer = setInterval(this.loopScanPayStatus, 5000)
            }).catch(err=>{
                console.log(err)
                this.paynowLoading = false
            })
        },
        backToPaynow(){
            this.showPayment = 1
            this.payRequest.qrCode = ''
            this.payRequest.orderId = 0
            if(this.timer !== null){
                console.log('clear interval')
                clearInterval(this.timer)
                this.timer = null
            }
        },
        loopScanPayStatus(){
            if(this.timer == null || this.payRequest.orderId == 0){
                console.log('loop reject')
                return
            }
            console.log('loop status interval')
            this.getOrderStatus({
                orderId: this.payRequest.orderId
            }).then(resp=>{
                console.log(resp)
                if(resp === 'paid'){
                    clearInterval(this.timer)
                    this.timer = null
                    this.clearGoods()
                    /*this.$alert('Order Paid Successfully, click to back to home page', 'Order Paid', {
                        confirmButtonText: 'Goto Home Page',
                        callback: ()=>{
                            this.clearGoods()
                            window.location.href = "/"
                        }
                    })*/
                    window.location.href = '/receipt/' + this.payRequest.orderId    
                }
            })
        }
    }
}
</script>

<style>
#checkout{
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}
.logo-img-checkout {
    width: 154px;
}
.checkout-sub-div{
    width: 50%;
    text-align: left;
    padding: 46px 66px 0px 0px;
}
.checkout-sub-div-2{
    width: 50%;
    text-align: left;
    padding: 66px 0px 0px 66px;
}
.right-line {
    border-right: 1px solid #E4E7ED;
    height: 100%;
}
#checkout_infomation{
    margin-top: 40px;
}
.checkout-shipping-method-top{
    display:-webkit-box;
    border-top: 1px solid #E4E7ED;
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
    padding: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.checkout-shipping-method-bottom{
    display: -webkit-box;
    border: 1px solid #E4E7ED;
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.el-radio__input.is-checked+.el-radio__label{
    color: #000 !important;
}
.el-radio__input.is-checked .el-radio__inner{
    background-color: #000 !important;
    border-color: #000 !important;
}
.dialog-footer{
    display: -webkit-box;
}
.chdkout-sub-div-bottom{
    height: calc(100vh - 800px);
}
.anouncement-div-2{
    background-color:#E6A23C;
    color:#fff;
    padding: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    height: 18px;
    text-align: center;
    border-radius: 2px;
}
.anouncement-div-3{
    margin-top: 10px;
    color:#EE1B3B;
    padding: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    border-radius: 2px;
}
#checkout_payment{
    margin-top: 40px;
}
.checkout-payment-method-top{
    display:-webkit-box;
    border-top: 1px solid #E4E7ED;
    border-left: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED;
    border-bottom: 1px solid #E4E7ED;
    padding: 5px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.checkout-payment-info-column {
    display:-webkit-box;
    margin:10px;
    padding:10px;
    border-bottom:1px solid #DCDFE6;
    font-size: 14px;
}
.checkout-payment-info-column-2 {
    display:-webkit-box;
    margin:10px;
    padding:10px;
    font-size: 14px;
}
#checkout_pay_code{
    margin-top: 0px;
}
</style>